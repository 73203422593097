import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/zh";
import SEO from "../components/seo";
import "../style/member-benefit.css";
import { Tabs } from "antd";
import $ from "jquery";

const MemberBenefitPage = (props) => {
  useEffect(() => {
    expandData();
  }, []);

  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState('benefit-all')
  const [activeKeyMobile, setMobileActiveKey] = useState('benefit-mobile-all')

  const expandData = () => {
    $(".benefit__mobile label").on("click", function () {
      $(this).toggleClass("benefit__tab--active");
    });
  };

  const specific_date = new Date('2022-01-09');
  const current_date = new Date();

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: inline-table
        }
      `,
        }}></style>

      <Layout location={props.location}>
        <SEO
          title="More benefits as you grow your membership status "
          keyword="ayana member benefits, benefits for member, ayana rewards"
          decription="Become an AYANA Rewards member and you will receive more benefits as you stay. As you grow your membership level, you will receive more as well. Become a member now and start to earn points!"
        />
        {current_date.getTime() > specific_date.getTime() ? (
            <>
            <div id="contentWrap">
              <div id="index_content" className="admin-setting">
                <div className="benefit__banner">&nbsp;</div>
                <div className="benefit">
                  <div className="benefit__banner__mobile mobile--only">
                    <img
                      className="full-width"
                      src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                    />
                  </div>
                  <div className="benefit__content">
                    <div className="container container--600">
                      <h1 className="text--center">会员福利</h1>
                      <p className="text--center">
                        阿雅娜会员礼遇有三个会员等级，每一等级都为您提供独一无二的优惠和奖励。
                        您的消费越高，奖励就越多。每次入住都能享受独家优惠。
                      </p>
                    </div>
                    <div className="container container--1000">
                      <table className="table table--benefit table--benefit--main">
                        <thead>
                          <tr>
                            <th valign="middle">计划概述</th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                              />
                              银卡
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                              />
                              金卡
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                              />
                              白金卡
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>会员升级所需最低积分</td>
                            <td>0</td>
                            <td>5,000</td>
                            <td>10,000</td>
                          </tr>
                          <tr>
                            <td>每年保持级别所需最低积分</td>
                            <td>0</td>
                            <td>1,500</td>
                            <td>5,000</td>
                          </tr>
                          <tr>
                            <td>积分有效期</td>
                            <td>10年</td>
                            <td>10年</td>
                            <td>10年</td>
                          </tr>
                          <tr>
                            <td>级别有效期</td>
                            <td>1年</td>
                            <td>1年</td>
                            <td>1年</td>
                          </tr>
                          <tr className="table--benefit--subheader">
                            <td colspan="4">赚取积分</td>
                          </tr>
                          <tr>
                            <td>每消费10,000印尼盾所赚取的积分</td>
                            <td>1分</td>
                            <td>1分</td>
                            <td>1分</td>
                          </tr>
                          <tr>
                            <td>每消费15,000印尼盾所赚取的积分（加拉璜凤凰木酒店）</td>
                            <td>1分</td>
                            <td>1分</td>
                            <td>1分</td>
                          </tr>
                          <tr>
                            <td>有效住宿所赚取的积分</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>餐饮消费所赚取的积分</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>水疗护理消费所赚取的积分</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr className="table--benefit--subheader">
                            <td colspan="4">会员福利</td>
                          </tr>
                          <tr>
                            <td>会员专享客房折扣</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>免费房型升级（一等）*</td>
                            <td>&nbsp;</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>有效消费可赚取额外积分</td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                            <td>25%</td>
                          </tr>
                          <tr>
                            <td>提前入住*</td>
                            <td>&nbsp;</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>延迟退房*</td>
                            <td>&nbsp;</td>
                            <td>14:00</td>
                            <td>16:00</td>
                          </tr>
                          <tr>
                            <td>健康计划福利**</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>生日入住酒店赠送礼物**</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Flower Studio花店会员独家折扣</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>Kawano餐厅集团会员独家折扣</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                        </tbody>
                      </table>
                      <small className="mart--lg" style={{ display: "block" }}>
                        *视供应情况而定
                        <br />
                        **视酒店情况而定 
                        <br />  
                        <Link className="link" to="/zh/terms-and-conditions-8">
                          参阅我们的条款和条件了解更多信息。
                        </Link>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
            ) : (
            <>
            <div id="contentWrap">
              <div id="index_content" className="admin-setting">
                <div className="benefit__banner">&nbsp;</div>
                <div className="benefit">
                  <div className="benefit__banner__mobile mobile--only">
                    <img
                      className="full-width"
                      src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                    />
                  </div>
                  <div className="benefit__content">
                    <div className="container container--600">
                      <h1 className="text--center">会员福利</h1>
                      <p className="text--center">
                        阿雅娜会员礼遇有三个会员等级，每一等级都为您提供独一无二的优惠和奖励。
                        您的消费越高，奖励就越多。每次入住都能享受独家优惠。
                      </p>
                    </div>
                    <div className="container container--1000">
                      <table className="table table--benefit table--benefit--main">
                        <thead>
                          <tr>
                            <th valign="middle">计划概述</th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                              />
                              银卡
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                              />
                              金卡
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                              />
                              白金卡
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>会员升级所需最低积分</td>
                            <td>0</td>
                            <td>5,000</td>
                            <td>10,000</td>
                          </tr>
                          <tr>
                            <td>每消费10,000印尼盾所赚取的积分*</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td>额外奖励积分</td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                            <td>15%</td>
                          </tr>
                          <tr>
                            <td>会员专享客房折扣（ayanarewards.com）</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>会员可享受Flower Studio花店的独家优惠</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>餐厅集团的会员专享优惠</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>生日期间入住获取礼物</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="benefit__special">
                      <h1>酒店特别权益</h1>
                      <div className="container container--1000">
                        <Tabs
                          className="benefit__tab"
                          defaultActiveKey="benefit-all"
                          size="large"
                          activeKey={activeKey}
                          onChange={(activeKey) => setActiveKey(activeKey)}
                        >
                          <TabPane tab="巴厘岛酒店" key="benefit-all">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">所有巴厘岛酒店</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    银卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    金卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    白金卡
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>免费客房升级（一等级）*</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>SPA护理优惠</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>提前入住*&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>延时退房*</td>
                                  <td>&nbsp;</td>
                                  <td>4 PM</td>
                                  <td>6 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane
                            tab="雅加达阿雅娜中城广场酒店"
                            key="benefit-ayana">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">所有巴厘岛酒店</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    银卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    金卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    白金卡
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>免费客房升级（一等级）*</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>免费使用俱乐部设施以及可享用多种优惠福利</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>餐饮消费赚取积分</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td><Link to="/zh/preferred-hotels-resorts">使用积分兑换I Prefer 奖赏计划证书</Link></td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>提前入住*&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>延时退房*</td>
                                  <td>2 PM</td>
                                  <td>4 PM</td>
                                  <td>6 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane tab="加拉璜凤凰木酒店" key="benefit-delonix">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">所有巴厘岛酒店</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    银卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    金卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    白金卡
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>迈阿密咖啡馆全天用餐折扣</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                  <td>20%</td>
                                </tr>
                                <tr>
                                  <td>商务中心设施使用折扣</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                </tr>
                                <tr>
                                  <td>机场接送折扣</td>
                                  <td>20%</td>
                                  <td>20%</td>
                                  <td>25%</td>
                                </tr>
                                <tr>
                                  <td>免费使用五人制足球场1小时</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>免费客房升级（一等级）*</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>餐饮消费赚取积分</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>提前入住*&nbsp;</td>
                                  <td>

                                  </td>
                                  <td>
                                    1PM
                                  </td>
                                  <td>
                                    1PM
                                  </td>
                                </tr>
                                <tr>
                                  <td>延时退房*</td>
                                  <td></td>
                                  <td>4 PM</td>
                                  <td>4 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane tab="阿雅娜科莫多维度假村" key="benefit-komodo">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">所有巴厘岛酒店</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    银卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    金卡
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    白金卡
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>陆上活动优惠</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>水上活动设备租凭优惠</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>SPA护理优惠</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>游船活动优惠*</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>
                        </Tabs>
                        <small className="mart--lg" style={{ display: "block" }}>
                          *提供的优惠因品牌而异/或视供应情况而定。
                          <br />
                          {activeKey == 'benefit-all' && (
                            <>
                              <span className="">
                                *不适用于岩石水疗
                            </span>
                              <br />
                            </>
                          )}

                          {activeKey == 'benefit-komodo' && (
                            <>
                              <span className="">
                                * 不适用于Lako di&rsquo;a巡航和包船航行
                            </span>
                              <br />
                            </>
                          )}
                          <Link className="link" to="/zh/terms-and-conditions-8">
                            参阅我们的条款和条件了解更多信息。
                          </Link>
                        </small>
                      </div>
                    </div>
                    <div className="benefit__mobile">
                      <h1>酒店特别权益</h1>
                      <div className="container container--1000">
                        <label
                          className="benefit__tab--active"
                          htmlFor="benefit-mobile-all">
                          巴厘岛酒店
                        </label>
                        <input
                          defaultChecked={true}
                          id="benefit-mobile-all"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-all')}
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">所有巴厘岛酒店</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                银卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                金卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                白金卡
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>免费客房升级（一等级）*</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>SPA护理优惠</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>提前入住*&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>延时退房*</td>
                              <td>&nbsp;</td>
                              <td>4 PM</td>
                              <td>6 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-ayana">
                          雅加达阿雅娜中城广场酒店
                        </label>
                        <input
                          id="benefit-mobile-ayana"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-ayana')}
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">所有巴厘岛酒店</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                银卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                金卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                白金卡
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>免费客房升级（一等级）*</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>免费使用俱乐部设施以及可享用多种优惠福利</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>餐饮消费赚取积分</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td><Link to="/zh/preferred-hotels-resorts">使用积分兑换I Prefer 奖赏计划证书</Link></td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>提前入住*&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>延时退房*</td>
                              <td>2 PM</td>
                              <td>4 PM</td>
                              <td>6 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-delonix">
                          加拉璜凤凰木酒店
                        </label>
                        <input
                          id="benefit-mobile-delonix"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-delonix')}
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">所有巴厘岛酒店</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                银卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                金卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                白金卡
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>生日折扣就在Miami 咖啡厅</td>
                              <td>20%</td>
                              <td>20%</td>
                              <td>20%</td>
                            </tr>
                            <tr>
                              <td>商务中心设施使用折扣</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>10%</td>
                            </tr>
                            <tr>
                              <td>机场接送折扣</td>
                              <td>20%</td>
                              <td>20%</td>
                              <td>25%</td>
                            </tr>
                            <tr>
                              <td>免费使用五人制足球场1小时</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>免费客房升级（一等级）*</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>餐饮消费赚取积分</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>提前入住*&nbsp;</td>
                              <td>

                              </td>
                              <td>
                                1 PM
                              </td>
                              <td>
                                1 PM
                              </td>
                            </tr>
                            <tr>
                              <td>延时退房*</td>
                              <td></td>
                              <td>4 PM</td>
                              <td>4 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-komodo">
                          阿雅娜科莫多维度假村
                        </label>
                        <input
                          id="benefit-mobile-komodo"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-komodo')}
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">所有巴厘岛酒店</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                银卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                金卡
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                白金卡
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>陆上活动优惠</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>水上活动设备租凭优惠</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>SPA护理优惠</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>游船活动优惠*</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                          </tbody>
                        </table>
                        <small className="mart--lg" style={{ display: "block" }}>
                          *提供的优惠因品牌而异/或视供应情况而定。
                          <br />
                          {activeKeyMobile == 'benefit-mobile-all' && (
                            <>

                              <span className="">
                                *不适用于岩石水疗
                              </span>
                              <br />
                            </>
                          )}
                          {activeKeyMobile == 'benefit-mobile-komodo' && (
                            <>

                              <span className="">
                                * 不适用于Lako di&rsquo;a巡航和包船航行
                              </span>
                              <br />
                            </>
                          )}
                          <Link className="link" to="/zh/terms-and-conditions-8">
                            请参阅我们的条款和条件了解更多信息。
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
        )}
      </Layout>
    </>
  );
};

export default MemberBenefitPage;
